<template lang="pug">
  v-tabs(color="primary" v-model="activeTab" background-color="transparent" height="40px").app-tabs
    v-tab(v-for="tab in tabs" :key="tab.name" @click="switchTab(tab.value)")
      div {{ tab.label }}
        btn(small).ml-2.tab-count {{ tab.count }}
</template>

<script>
import { REAPPLY_APPLICATIONS_TAB } from '../core/reapplyApplications-const'

export default {
  name: 'ReapplyApplicationsTabs',

  props: {
    counts: Object
  },

  data: () => ({
    activeTab: 0
  }),

  computed: {
    tabs() {
      return [
        {
          name: REAPPLY_APPLICATIONS_TAB.TASKS,
          label: 'Task List',
          count: this.counts[REAPPLY_APPLICATIONS_TAB.TASKS],
          value: REAPPLY_APPLICATIONS_TAB.TASKS,
        },
        {
          name: REAPPLY_APPLICATIONS_TAB.UNPAID_REAPPLIED,
          label: 'Not paid Reapplied',
          count: this.counts[REAPPLY_APPLICATIONS_TAB.UNPAID_REAPPLIED],
          value: REAPPLY_APPLICATIONS_TAB.UNPAID_REAPPLIED
        },
        {
          name: REAPPLY_APPLICATIONS_TAB.FUTURE,
          label: 'Future tasks',
          count: this.counts[REAPPLY_APPLICATIONS_TAB.FUTURE],
          value: REAPPLY_APPLICATIONS_TAB.FUTURE
        },
        {
          name: REAPPLY_APPLICATIONS_TAB.PAID_REAPPLIED,
          label: 'Paid Reapplied',
          count: this.counts[REAPPLY_APPLICATIONS_TAB.PAID_REAPPLIED],
          value: REAPPLY_APPLICATIONS_TAB.PAID_REAPPLIED
        },
       
        {
          name: REAPPLY_APPLICATIONS_TAB.CANCELED,
          label: 'Canceled',
          count: this.counts[REAPPLY_APPLICATIONS_TAB.CANCELED],
          value: REAPPLY_APPLICATIONS_TAB.CANCELED
        }
      ]
    }
  },

  created() {
    this.setTabByQuery()
  },

  methods: {
    async switchTab(value) {
      if (this.$route.query.tab === value) return

      await this.$router.replace({
        query: {
          ...this.$route.query,
          tab: value,
          page: 1
        }
      })
      this.$emit('change')
    },

    setTabByQuery() {
      let name = this.$route.query.tab
      let tabIndex = this.tabs.findIndex(tab => tab.value === name)
      if (tabIndex < 0) return
      this.activeTab = tabIndex
    }
  },

  components: {
    btn: () => import('@/components/global/BtnCustom.vue')
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/tabsNew";
</style>
